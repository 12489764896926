@media only screen and (max-width: 768px) {
    .elevate {
        box-shadow: none !important;
        border: 1px solid #eee;
    }
    .elevate-xs {
        box-shadow: none !important;
        border: 1px solid #eee;
    }
    .planner-wrapper, .planner-parent {
        width: 100%;
    }
    .planner-setup-parent {
        flex-direction: column;
        max-width: none;
        width: 260px
    }
    .breadcrumb {
        zoom: .7;
    }
    .fab-parent {
        left: 0;
        bottom: 16px;
    }
    .trip-planner-auth-parent .signin-container {
        padding: 0
    }
    .trip-planner-auth-parent .orange-but {
        width: 200px;
        margin-left: 0;
        margin-right: 0;
    }
    .planner-parent .elevate {
        top: 384px;
        right: 25%;
        left: auto;
    }
    .planner-search-parent{
        padding:0;
        padding-top:55px
    }
}