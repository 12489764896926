/* general */

.elevate {
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.elevate-xs {
	box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.268), 0 2px 10px 0 rgba(0, 0, 0, 0.08) !important;
}

.planner-background {
	margin-top: -100px;
	position: absolute;
	height: 52vh;
	width: 100%;
	flex: 1;
	background-color: #237474;
	background-image: url("https://www.transparenttextures.com/patterns/arches.png");
	/*
		This is mostly intended for prototyping; please download the pattern and
		re-host for production environments. Thank you!
	*/
}

.planner-background::after {
	content: "";
	position: absolute;
	top: 52vh;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 50px 50vw 0 50vw;
	border-color: rgb(35, 116, 116) transparent transparent transparent;
	z-index: -1;
}

.planner-modal {
	margin-top: -100px;
	text-align: center;
	position: fixed;
	/* Stay in place */
	z-index: 1;
	/* Sit on top */
	padding-top: 100px;
	/* Location of the box */
	left: 0;
	top: 0;
	width: 100%;
	/* Full width */
	height: 100%;
	/* Full height */
	overflow: auto;
	/* Enable scroll if needed */
	background-color: rgb(0, 0, 0);
	/* Fallback color */
	background-color: rgba(0, 0, 0, 0.4);
	/* Black w/ opacity */
}

/* Modal Content */

.planner-modal-content {
	background-color: #fefefe;
	margin: auto;
	padding: 10px;
	border: 1px solid #888;
	border-radius: 5px;
	width: 550px;
}

.planner-modal-content2 {
	background-color: #fefefe;
	margin: auto;
	padding: 40px;
	border: 1px solid #888;
	border-radius: 10px;
	width: 70%;
}

/* The Close Button */

.planner-close {
	color: #aaaaaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
}

.planner-close:hover, .close:focus {
	color: #000;
	text-decoration: none;
	cursor: pointer;
}

.autocomplete-dropdown-container {
	z-index: 1500;
	position: absolute;
	top: 250px;
	width: 100%;
	background-color: white;
}

.session-background {
	margin-top: -100px;
	position: absolute;
	height: 52vh;
	width: 100%;
	flex: 1;
	background-color: rgb(255, 255, 255);
}

.session-background::after {
	content: "";
	position: absolute;
	top: 52vh;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 50px 50vw 0 50vw;
	border-color: rgb(116, 50, 35) transparent transparent transparent;
	z-index: -1;
}

.breadcrumb {
	flex: 1;
	align-self: center;
	/*centering*/
	display: flex;
	overflow: hidden;
	/*
		Lets add the numbers for each link using CSS counters. flag is the name of
		the counter. to be defined using counter-reset in the parent element of the
		links
	*/
	counter-reset: flag;
}

.breadcrumb a {
	flex: 1;
	text-decoration: none;
	outline: none;
	display: block;
	float: left;
	font-size: 12px;
	line-height: 36px;
	color: white;
	/*need more margin on the left of links to accomodate the numbers*/
	padding: 0 10px 0 60px;
	background: #666;
	background: linear-gradient(#666, #333);
	position: relative;
}

/*
	since the first link does not have a triangle before it we can reduce the
	left padding to make it look consistent with other links
*/

.breadcrumb a:first-child {
	padding-left: 46px;
}

.breadcrumb a:first-child:before {
	left: 14px;
}

.breadcrumb a:last-child {
	padding-right: 20px;
}

/*hover/active styles*/

.breadcrumb a.active, .breadcrumb a:hover {
	background: #333;
	background: linear-gradient(#333, #000);
}

.breadcrumb a.active:after, .breadcrumb a:hover:after {
	background: #333;
	background: linear-gradient(135deg, #333, #000);
}

/*adding the arrows for the breadcrumbs using rotated pseudo elements*/

.breadcrumb a:after {
	content: '';
	position: absolute;
	top: 0;
	right: -18px;
	/*half of square's length*/
	/*same dimension as the line-height of .breadcrumb a */
	width: 36px;
	height: 36px;
	/*as you see the rotated square takes a larger height. which makes it tough to position it properly. So we are going to scale it down so that the diagonals become equal to the line-height of the link. We scale it to 70.7% because if square's: 
	length = 1; diagonal = (1^2 + 1^2)^0.5 = 1.414 (pythagoras theorem)
	if diagonal required = 1; length = 1/1.414 = 0.707*/
	transform: scale(0.707) rotate(45deg);
	/*we need to prevent the arrows from getting buried under the next link*/
	z-index: 1;
	/*background same as links but the gradient will be rotated to compensate with the transform applied*/
	background: #666;
	background: linear-gradient(135deg, #666, #333);
	/*stylish arrow design using box shadow*/
	box-shadow: 2px -2px 0 2px #f2f2f2, 3px -3px 0 2px rgba(255, 255, 255, 0.1);
	/*
		5px - for rounded arrows and 
		50px - to prevent hover glitches on the border created using shadows*/
	border-radius: 0 5px 0 50px;
}

we dont need an arrow after the last link .breadcrumb a:last-child:after {
	content: none;
}

/*we will use the :before element to show numbers*/

.breadcrumb a:before {
	text-align: center;
	content: counter(flag);
	counter-increment: flag;
	/*some styles now*/
	border-radius: 100%;
	width: 20px;
	height: 20px;
	line-height: 20px;
	margin: 8px 0;
	position: absolute;
	top: 0;
	left: 30px;
	background: #444;
	background: linear-gradient(#444, #222);
	font-weight: bold;
}

.flat span {
	text-transform: capitalize;
	cursor: pointer;
}

.flat a, .flat a:after {
	background: white;
	color: rgb(16, 20, 24);
	transition: all 0.5s;
}

.flat a:before {
	background: white;
	box-shadow: 0 0 0 1px #ccc;
}

.flat a:hover, .flat a.active, .flat a:hover:after {
	background: linear-gradient(to left, rgb(10, 127, 181), rgb(5, 159, 170));
}

.flat a:hover span {
	color: white;
}

.flat a.active:after {
	background: rgb(10, 127, 181);
}

.flat a:hover:after {
	background: rgb(10, 127, 181);
}

.flat a.active span {
	color: white;
}

.fab {
	background: rgb(10, 127, 181);
	background: linear-gradient(to left, rgb(10, 127, 181), rgb(5, 159, 170));
	height: 60px;
	width: 60px;
	color: white;
	justify-content: center;
	align-items: center;
	display: flex;
	border-radius: 100px;
	cursor: pointer;
	transition: 1s;
}

.fab-disabled {
	background: #ccc;
	height: 60px;
	width: 60px;
	color: white;
	justify-content: center;
	align-items: center;
	display: flex;
	border-radius: 100px;
	cursor: not-allowed;
	transition: 1s;
}

.fab:hover {
	background: linear-gradient( to right, rgb(10, 127, 181), rgb(5, 159, 170));
}

.checkout-but {
	background: rgb(10, 127, 181);
	background: linear-gradient(to left, rgb(10, 127, 181), rgb(5, 159, 170));
	height: 40px;
	flex: 1;
	color: white;
	justify-content: center;
	align-items: center;
	display: flex;
	border-radius: 100px;
	cursor: pointer;
	transition: 1s;
	margin-left: 15px;
	margin-right: 15px;
}

.checkout-but-disabled {
	background: #ccc;
	height: 40px;
	flex: 1;
	color: white;
	justify-content: center;
	align-items: center;
	display: flex;
	border-radius: 100px;
	cursor: not-allowed;
	transition: 1s;
	margin-left: 15px;
	margin-right: 15px;
}

.session-modal {
	margin-top: -100px;
	text-align: center;
	position: fixed;
	/* Stay in place */
	z-index: 1;
	/* Sit on top */
	padding-top: 100px;
	/* Location of the box */
	left: 0;
	top: 0;
	width: 100%;
	/* Full width */
	height: 100%;
	/* Full height */
	overflow: auto;
	/* Enable scroll if needed */
	background-color: rgb(0, 0, 0);
	/* Fallback color */
	background-color: rgba(0, 0, 0, 0.8);
	/* Black w/ opacity */
}

/* Modal Content */

.session-modal-content {
	background: rgb(10, 127, 181);
	background: linear-gradient(to left, rgb(10, 127, 181), rgb(5, 159, 170));
	margin: auto;
	padding: 10px;
	border-radius: 5px;
	width: 1150px;
	height: 500px;
	padding-right: 50px;
	position: relative;
}

.session-modal-content2 {
	background-color: #fefefe;
	margin: auto;
	padding: 40px;
	border: 1px solid #888;
	border-radius: 10px;
	width: 70%;
}

/* The Close Button */

.session-close {
	color: #fffefe;
	float: right;
	font-size: 28px;
	font-weight: bold;
}

.session-close:hover, .close:focus {
	color: #000;
	text-decoration: none;
	cursor: pointer;
}

.selector-modal {
	z-index: 3000;
	font-size: 15px;
	background: rgb(249, 253, 255);
	margin-right: 20px;
	height: 60px;
	width: 60px;
	color: rgb(10, 127, 181);
	justify-content: center;
	align-items: center;
	display: flex;
	border-radius: 100px;
	cursor: pointer;
	transition: .5s;
}

.selector-modal-active {
	font-size: 30px;
	background: rgb(10, 127, 181);
	margin-right: 20px;
	height: 60px;
	width: 60px;
	color: rgb(255, 255, 255);
	justify-content: center;
	align-items: center;
	display: flex;
	border-radius: 100px;
	cursor: pointer;
	transition: .5s;
}

.selector-modal:hover {
	background: red;
	color: rgb(255, 255, 255);
}

.planner-parent {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 60px;
	margin-bottom: 60px;
}

.planner-wrapper {
	width: 1024px;
}

.planner-flex {
	display: flex;
}

.location-parent .autocomplete-places-parent {
	display: flex;
	justify-content: center;
}

.planner-setup-parent {
	display: flex;
	justify-content: center;
	max-width: 525px;
	width: 100%
}

.fab-parent {
	position: absolute;
	/* left: -175px; */
	left: "40%";
	bottom: 16px;
	justify-content: center;
	align-items: center;
	/* bottom: 150px; */
	display: flex;
	width: 100%
}
/* .planner-search-parent a,.tab-caption,.tab-dropdown-parent{
	color:black
} */
.planner-search-parent{
	padding:40px;
	padding-top:165px
}
.planner-search-parent .list-property-bt{
	display: none;
}
