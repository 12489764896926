.view-gallery-grid-container {
    display: grid;
    grid-template-columns: auto auto;
    padding: 10px;
}

.view-gallery-grid-item {
    overflow: hidden;
    height: 130px;
    width: 130px;
    font-size: 16px;
    margin: 10px;
    margin-bottom: 20px;
}

.view-inner-width {
    width: 1050px;
    margin: auto;
    padding-bottom: 20px;
}

.view-gallery-parent {
    display: flex;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
}

.view-location-parent {
    display: flex;
    padding: 20px;
}

.view-room-item {
    display: flex;
}

.view-room-item-details {
    display: flex;
    flex: 1;
}

.view-amenities {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 5px;
    margin: 10px
}
.view-show-directions{
    background-color: #0a7fb5;
    border: 1px solid #0a7fb5;
    color: white;
    font-weight: bold;
    font-size: 11px;
    padding: 20px;
    padding-top: 11px;
    padding-bottom: 10px;
    display: inline;
    max-height: 40px;
    border-radius: 20px;
    transition: .3s;
    cursor: pointer;
    text-align: center;
}

.data-table tr {
    height: 52px;
    border-bottom-width: 1px;
    border-color: #ccc;
}

.data-table td {
    padding: 0px 16px 0px 16px;
}

@media only screen and (max-width: 768px) {
    .view-inner-width {
        width: 100%;
    }
    .view-gallery-parent {
        flex-direction: column;
        height: 350px;
    }
    .view-location-parent {
        flex-direction: column;
    }
    .view-room-item {
        flex-direction: column;
    }
    .view-room-item-details {
        flex-direction: column;
    }
    .view-amenities {
        grid-template-columns: auto auto;
    }
}