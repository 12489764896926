.mobile-header-search {
   color: white !important;
   height: 56px;
   padding: 16px 26px;
   position: absolute;
   width: 100%;
   font-size: 20px;
   font-weight: 400;
   top: 0;
   z-index: 6000;
   display: none;
   flex-direction: column;
   background: #000;
}

.mobile-header svg {
   font-size: 25px;
}

@media only screen and (max-width: 768px) {
   .left-menu-parent {
      position: fixed;
      display: none;
      left: -100%
   }
   .mobile-header-search {
      display: flex;
   }
}