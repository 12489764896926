.mobile-header {
    color: white !important;
    height: 56px;
    padding: 16px 26px;
    position: absolute;
    width: 100%;
    font-size: 20px;
    font-weight: 400;
    top: 0;
    z-index: 6000;
    display: none;
    flex-direction: column;
}

.mobile-header svg {
    font-size: 25px;
}

.tab-mobile-inputs {
    display: none;
}

.mobile-menu-items-parent {
    width: 100%;
    height: 100vh;
    background: #001d25f7;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    position: fixed;
    z-index: 6000;
    top: 0;
    padding-top: 26vh;
}

.mobile-menu-items-parent a {
    padding: 20px 30px;
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
}

@media only screen and (max-width: 768px) {
    ::-webkit-scrollbar {
        /* width: 1px; */
        height: 0px;
    }
    ::-webkit-scrollbar-thumb {
        cursor: pointer;
        background: #1a0626;
    }
    .footer-links {
        flex-direction: column;
    }
    .display-port {
        width: 100%;
    }
    .mobile-hidden {
        display: none;
    }
    .desktop-hidden {
        display: block;
    }
    .flex-column-mobile .ad {
        justify-content: space-around;
    }
    .grid-item {
        /* width: 85%;
        height: 165px */
        width: calc(100% - 32px);
        margin-bottom: 16px;
        place-self: center;
    }
    .grid-container-4 {
        grid-template-columns: repeat(2, 1fr);
    }
    .mobile-header {
        display: flex;
    }
    .tab-parent {
        margin-top: 8px;
    }
    .selector {
        overflow-x: scroll;
        justify-content: left;
        margin-top: 9px;
        padding-left: 23px;
    }
    .tab-content {
        flex-direction: column;
        margin-top: 0;
        padding: 18px;
        width: 80%;
        place-self: center;
    }
    .tab-caption {
        font-size: 20px;
        margin-top: 35px;
        align-self: flex-start;
    }
    .tab-dropdown-parent {
        padding-top: 0;
    }
    .tab-mobile-inputs {
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .tab-mobile-text-input {
        background-color: white;
        height: 45px;
        width: 85%;
        border-radius: 10px;
        display: flex;
        align-items: center;
    }
    .tab-mobile-text-input input {
        border: none;
        outline: none;
        font-size: 20px;
    }
    .tab-mobile-text-input svg {
        font-size: 25px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .top-background, .top-background-texture, .backdrop {
        height: 70vh
    }
    .top-background-image {
        display: none;
    }
    .backdrop {
        background: linear-gradient(to right, rgb(33 74 82 / 67%) 0%, rgba(6, 6, 56, .4) 43%, rgb(9 33 38 / 50%) 100%);
    }
    .safari-grad {
        background: linear-gradient(-45deg, #00ccff, #ff0000, #ca1104, #02e5f5);
    }
    .triangle-mask-parent {
        top: 65.1vh
    }
    .section-2 {
        margin-top: 70vh;
    }
    .tab-input-fields {
        flex-direction: column;
        margin-top: -5px;
        width: 80%;
        place-self: center;
    }
    .tab-input-fields-content {
        flex-direction: column;
    }
    .tab-date-input-parent {
        margin-left: 0px;
    }
    .tab-date-input {
        width: 100%;
    }
    .index-search-but {
        margin-top: 3px;
        height: 39px;
        margin-left: 0px;
    }
    .tab-location-search-input input {
        font-size: 19px;
    }
    .ad-image {
        margin-left: 0px;
        margin-right: 0px;
    }
    .footer-download-content {
        padding: 15px
    }
    .footer-parent {
        min-height: 1130px;
    }
    .autocomplete-places-parent ul {
        top: 247px;
        left: 14%;
    }
    .tab-input-fields {
        background: #0000;
    }
    .mobile-14 {
        font-size: 14px;
    }
    .desktop-pad-top-37{
        padding-top: 0;
    }
    .logo-text{
        font-size: 0.9em;
    }
}

@media screen and (max-width: 451px) {
    .tab-content {
        width: 90%;
    }

    .tab-input-fields {
        width: 90%;
    }
    .grid-container-4 {
        /* grid-template-columns: auto auto; */
        grid-template-columns: repeat(1, 1fr);
    }
    .flex-column-mobile {
        flex-direction: column !important;
    }
    .grid-item {
        width: 100%;
    }
}
