.dashsum-parent {
  display: flex;
}

.desktop-left-padded {
  margin-left: 70px;
  position: absolute;
  padding: 50px;
  padding-top: 20px;
  flex: 1
}

/* Responsive global */

@media screen and (max-width: 800px) {
  .dashsum-parent {
    display: block;
  }
}

@media screen and (max-width: 670px) {
  .desktop-left-padded {
    margin-left: 0px;
  }
}

.admin-display-port {
  width: 1200px;
  align-self: center;
  position: relative;
}

.backTriangle {
  height: 440px;
  width: 100%;
  background-color: white;
  position: absolute;
  left: 70px
}

.backTriangle::after {
  content: "";
  position: absolute;
  top: 440px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 48px 48vw 0 48vw;
  border-color: #ffffff transparent transparent transparent;
}

.backTriangle::before {
  content: "";
  position: absolute;
  top: 441px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 48px 48vw 0 48vw;
  border-color: #e1dfdf transparent transparent transparent;
}

.nav-rail {
  width: 72px;
  overflow-y: hidden;
}

.leftMenu {
  padding: 0;
  margin: 0;
}

.leftMenu a {
  display: flex;
  text-align: center;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.leftSuperMenu {
  padding: 0;
  margin: 0;
}

.leftSuperMenu li {
  text-decoration: none;
  list-style: none;
  padding: 13px;
  color: white;
  display: flex;
  flex-direction: column;
}

.leftSuperMenu a {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-content: center;
  color: white;
  font-weight: bold;
}

.leftSuperMenuIcon {
  color: white;
  font-size: 1.1em !important;
  align-self: center;
}

.leftInsideMenu {
  padding: 0;
  margin: 0;
  margin-top: 0px;
}

.leftInsideMenu li {
  text-decoration: none;
  list-style: none;
  padding: 10px;
  padding-left: 30px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 1px;
  transition: .3s;
}

.leftMenuIcon {
  font-size: 30px;
}

.leftMenuText {
  margin-top: 6px;
  font-size: 11px;
}

.nav-rail__more-section .leftMenuText {
  display: none;
}

.topItem {
  text-decoration: none;
  list-style: none;
  padding: 2px;
  transition: .3s;
}

.topItemDrop {
  text-decoration: none;
  list-style: none;
  padding: 10px;
  color: white;
}

/* .leftSuperMenu .active {} */

.leftSuperMenu li:hover {
  border-radius: 20px;
}

.leftInsideMenu {
  margin-top: 10px;
  border-top: 1px solid white;
}

.leftInsideMenu li:hover {
  background-color: orangered;
  border-radius: 20px;
}

.leftInsideMenu .active {
  background-color: #ff0000;
  border-radius: 20px;
}

.topItem:hover {
  border-radius: 1000px;
  background-color: rgb(8, 5, 4);
}

.leftMenu a {
  text-decoration: none;
  color: #666;
  padding: 5px;
}

.leftMenu .active {
  border-radius: 0px;
  color: #fff !important
}

/* Modal */

/* The Modal (background) */

.modal {
  text-align: center;
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 10px;
  border: 1px solid #888;
  border-radius: 10px;
  width: 30%;
}

.modal-content2 {
  background-color: #fefefe;
  margin: auto;
  padding: 40px;
  border: 1px solid #888;
  border-radius: 10px;
  width: 70%;
}

/* The Close Button */

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/*form*/

.form input, textarea {
  margin: 10px;
  height: 45px;
  border: 1px solid #ddd;
  outline: 0;
  padding: 10px;
  border-radius: 2px;
  display: block;
  margin-top: 1em;
  font-family: 'Merriweather', sans-serif;
  box-shadow: 0 1px 1px rgba(black, 0.1);
  resize: none;
}

.form textarea {
  width: 100%
}

.form select {
  margin: 10px;
  padding-left: 10px;
  border: 1px solid #ddd;
  outline: 0;
  border-radius: 2px;
  display: block;
  min-height: 37px;
  margin-top: 1em;
  font-family: 'Merriweather', sans-serif;
  box-shadow: 0 1px 1px rgba(black, 0.1);
  resize: none;
}

.form textarea {
  height: 126px;
}

.inputStyle {
  align-self: 'center';
  width: '100%';
  padding: 12;
  margin-top: 12;
  display: 'inline-block';
  border: 1;
  border-style: 'solid';
  border-color: '#ccc';
  border-radius: 4;
  box-sizing: 'border-box'
}

.multiSelect option {
  padding-top: 10px;
  font-weight: bold;
  font-size: 12px;
  color: #2b3e51;
  text-transform: uppercase;
}

.selectDateForm input {
  height: 16px;
  border: 1px solid #ccc;
  outline: 0;
  padding: 1em;
  border-radius: 8px;
  display: block;
  width: 100%;
  margin-top: 1em;
  font-family: 'Merriweather', sans-serif;
  box-shadow: 0 1px 1px rgba(black, 0.1);
  resize: none;
}

.button {
  margin: 10px;
  display: inline-block;
  cursor: pointer;
  padding: 10px 20px;
  border: 1px solid #ddd;
  font-weight: bold;
  border-radius: 20px;
  text-align: center;
}

.dev-selector {
  display: flex;
  font-weight: 700;
  align-items: center;
}

.dev-selector-item {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 36px;
  color: #121212;
  cursor: pointer;
  /* transition: 0.5s; */
}

.dev-selector-item:hover {
  color: #9bc9ff;
}

.dev-selector .active {
  /* color: #ff8500; */
  background: #ff725e;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dev-selector .active .dev-selector-underline {
  height: 5px;
  flex: 1;
  background: #ff725e;
  border-radius: 10px;
}

#map {
  width: 100%;
  height: 480px;
}

@media screen and (min-width: 1900px) {
  #app {
    width: 83vw
  }
}

@media screen and (max-width: 1024px) {
  .nav-rail {
    width: 256px;
    padding: 8px 16px 8px 16px;
  }

  .nav-rail__desktop {
    display: none;
  }

  .nav-rail__more-section .topItem {
    margin-bottom: 8px;
    background-color: #0c1d2d;
  }

  .nav-rail__more-section .leftMenuText {
    display: block;
  }

  .leftMenu a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
  }

  .leftMenuIcon {
    font-size: 24px;
  }

  .leftMenuText {
    font-size: 14px;
    margin-left: 8px;
  }
}