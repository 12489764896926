.text-input-container {
  position: relative;
  border-width: 2px;
  border-radius: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
}

.text-input-container:focus-within {
  border-color: #ff5900;
}

.text-input-container:focus-within .text-input-label {
  font-size: 11px;
  top: -8px;
  bottom: unset;
  background-color: white;
  padding: 1px 4px 1px 4px;
  color: #ff5900;
}

.text-input {
  border-width: 0px;
  display: block;
  appearance: none;
  width: 100%;
  background-color: transparent;
}

.text-input:focus {
  outline: none;
  color: black;
}

.text-input:not(:placeholder-shown) + .text-input-label {
  top: -8px;
  bottom: unset;
  font-size: 11px;
  background-color: white;
  padding: 1px 4px 1px 4px;
}

.text-input-label {
  position: absolute;
  transition-property: top font-size;
  transition-duration: 150ms;
  transition-timing-function: linear;
  transform-origin: 0%;
  top: 8px;
  left: 8px;
  pointer-events: none;
  /* z-index: -1; */
}
