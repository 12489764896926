.description_text {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 5;
   /* number of lines to show */
   -webkit-box-orient: vertical;
   font-size: 13px;
   margin-top: 20px;
}

.description_text_main {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 11;
   /* number of lines to show */
   -webkit-box-orient: vertical;
   font-size: 12px;
}

.small-search-bar select:focus-visible {
   outline: none;
}

.small-search-bar label {
   font-size: 11px;
   color: white;
}

.small-search-but {
   background-color: rgb(7, 160, 220);
   width: 100%;
   border: 0;
   margin-top: 16px;
}

.small-search-but:hover {
   background-color: rgb(79, 205, 255);
}

.small-search-bar:focus-visible {
   outline: none;
}

.search-background {
   margin-top: 0;
   position: absolute;
   height: 100px;
   width: 100%;
   flex: 1;
   background-color: transparent;
}

.search-background::after {
   z-index: -5000;
   content: "";
   position: absolute;
   top: 22px;
   height: 100vh;
   width: 100%;
   flex: 1;
   background-color: #fff;
}

.map-background {
   height: 100px;
   background-image: url('../assets/map.png');
}

/* 
.search-background::after {
   content: "";
   position: absolute;
   top: 52vh;
   width: 0;
   height: 0;
   border-style: solid;
   border-width: 50px 50vw 0 50vw;
   border-color:  rgb(17, 56, 56) transparent transparent transparent;
   z-index: -1;
} 
*/

.rc-slider-handle {
   border: solid 2px #f00 !important;
}

.rc-slider-track {
   background-color: #f00 !important;
}

.search-parent {
   scrollbar-width: 0;
   justify-content: center;
   margin-top: 46px
}

.search-background-parent {
   width: 100%;
   height: 200%;
   position: absolute;
   background: #f9f9f9;
   top: 0px;
   /* box-shadow: inset 0 7px 13px -7px #100e0ebf; */
}

.page-parent {
   display: flex;
   z-index: 3000;
   padding-top: 25px;
}

.main-column {
   padding-left: 20px;
   flex: 1
}

@keyframes placeHolderShimmer {
   0% {
      background-position: 0px 0;
   }
   100% {
      background-position: 100em 0;
   }
}

.shimmer {
   animation-duration: 10s;
   animation-fill-mode: forwards;
   animation-iteration-count: infinite;
   animation-name: placeHolderShimmer;
   animation-timing-function: linear;
   background: #777;
   background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
   -webkit-backface-visibility: hidden;
   backface-visibility: hidden;
}

@keyframes imagePlaceHolderShimmer {
   0% {
      background-position: -468px 0;
   }
   100% {
      background-position: 468px 0;
   }
}

.image-shimmer {
   animation-duration: 6s;
   animation-fill-mode: forwards;
   animation-iteration-count: infinite;
   animation-name: imagePlaceHolderShimmer;
   animation-timing-function: linear;
   background: #fff;
   background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
   -webkit-backface-visibility: hidden;
   backface-visibility: hidden;
}

.left-menu-parent {
   width: 260px
}

.selector-menu {
   height: 30px;
   display: flex;
   padding: 8px
}

.search-image {
   height: 182px;
   margin: 15px;
   width: 220px;
   border-radius: 3px;
   background-size: cover;
}

.selector-heading {
   font-weight: bold;
   font-size: 19px;
   flex: 1;
   color: #3f3c55;
   display: flex
}

.selector-number-of-results {
   font-size: 12px;
   color: #111;
}

.selector-menu-item {
   cursor: pointer;
   flex: 1;
   text-align: center;
   border-right: 3px solid #000;
   padding-top: 0px;
   font-size: 12px;
   color: #0a7fb5
}

.item-hotel-highlights {
   align-content: right;
   padding: 15px;
   border-left: 1px dotted #eee
}

.item-hotel-highlights-pusher {
   flex: 1
}
.item-details-parent{
   flex:1;
   display: flex;
}

.filter-sort-dropdown {
   display: none;
}

.filter-sort:hover .filter-sort-dropdown {
   display: block;
}

/* Tablet Styles */
@media screen and (max-width: 450px) {
   .search-image {
      display: block;
      width: 100%;
   }
   .image-shimmer {
      width: 100%;
   }
   .item-details-parent {
      flex-direction: column;
   }
}