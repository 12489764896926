.partner-content-container::-webkit-scrollbar {
  width: 0px;
}

.drawer-icon {
  display: block;
}

.alternative-drawer-icon {
  display: none;
}

@media screen and (min-width: 1025px) {
  .drawer-icon {
    display: none;
  }

  .alternative-drawer-icon {
    display: block;
  }
}
