.linestepbar-container {
    width: 100%;
    margin-top: 35px;
}

.linestepbar {
    counter-reset: step;
}

.linestepbar li {
    list-style-type: none;
    width: 23%;
    float: left;
    font-size: 16px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: #7d7d7d;
}

.linestepbar li:before {
    width: 40px;
    height: 40px;
    content: counter(step);
    counter-increment: step;
    line-height: 40px;
    border: 2px solid #7d7d7d;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: white;
    font-size: 20px;
}

.linestepbar li:after {
    width: 100%;
    height: 2px;
    content: '';
    position: absolute;
    background-color: #7d7d7d;
    top: 20px;
    left: -50%;
    z-index: -1;
}

.linestepbar li:first-child:after {
    content: none;
}

.linestepbar li.active {
    color: #ff735c;
}

.linestepbar li.active:before {
    border-color: #ff735c;
}

.linestepbar li.active+li:after {
    background-color: #ff735c;
}

.linestepbar li.active:after {
    background-color: #ff735c;
}

/* Style the buttons that are used to open and close the accordion panel */

.accordion {
    font-family: 'Poppins';
    border: 1px solid #eee !important;
    background-color: #666;
    color: #fff;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    font-weight: 700;
    display: flex;
}

.accordion-parent {
    display: flex;
    flex-direction: column;
    width: 600px;
    margin: auto;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */

.accordion-parent .active, .accordion:hover {
    background-color: #ff735c;
    color: white
}

/* Style the accordion panel. Note: hidden by default */

.accordion-parent .panel {
    padding: 0 18px;
    background-color: white;
    display: none;
    overflow: hidden;
    border: 1px solid #eee;
}

.accordion-parent .active {
    display: inline;
    padding: 50px;
    padding-top: 10px;
}

.header-active {
    background-color: #ff735c;
    color: white
}

.gpay-button.black {
    box-shadow: none;
    padding: 12px 24px 10px;
    width: 240px !important;
}

.credit-cards {
    background-image: url(../assets/credit-card-logos.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: top;
    width: 88%;
    margin-left: calc(12% / 2);
    margin-right: calc(12% / 2);
    height: 40px;
    margin: 20px;
}

.mpesa-logo {
    background-image: url(../assets/mpesa-logos.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: top;
    width: 42%;
    height: 55px;
    margin: 0px;
    margin-top: 0;
}

.summary-parent {
    display: flex;
    width: 900px;
    margin: auto
}

.summary-left {
    flex: 1;
    border: 2px solid #eee
}

.summary-right {
    width: 300px;
    border: 2px solid #eee;
    border-left: 0
}
.checkout-signin-parent .signin-container{
padding:0
}
@media only screen and (max-width: 768px) {
    .summary-parent {
        flex-direction: column;
        min-width:100vw;
        height: 100vh;
        overflow-y: scroll;
    }
    .summary-left {
        width:100%;
        height: 100vh;
    }
    .summary-right {
        width:100%;
        height: 100vh;
    }
    .mpesa-logo {
        width: 20%;
    }
    .credit-cards {
        width: 40%;
    }
}

@media screen and (max-width: 451px) {
    .mpesa-logo {
        width: 42%;
    }

    .credit-cards {
        width: 80%;
    }
}