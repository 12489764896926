

.safari-grad {
	background: linear-gradient(-45deg, #4ebbd5, #a71b1b, #a73e37, #059faa);
	background-size: 400% 400%;
	animation: gradient 20s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.pointer {
	cursor: pointer;
}

::-webkit-scrollbar {
	width: 5px;
	height: 8px;
}

::-webkit-scrollbar-thumb {
	cursor: pointer;
	background: #202020;
}

.flex-div {
	display: flex;
	flex-direction: column;
}

a:hover, a:visited, a:link, a:active {
	text-decoration: none;
	transition: .4s;
}

.header-content a:hover {
	color: orange;
}

body {
	overflow-x: hidden;
}

.backdrop {
	position: absolute;
	height: 500px;
	width: 100%;
	flex: 1;
	background: rgb(16, 60, 69);
	background: linear-gradient(to right, rgb(38 9 9 / 68%) 0%, #07072d8a 43%, rgb(38 9 9 / 58%) 100%);
	clip-path: polygon(0 0, 100% 0%, 100% 95%, 50% 100%, 0 95%);
}

.top-background-texture {
	position: absolute;
	height: 500px;
	width: 100%;
	flex: 1;
	background-image: url("https://www.transparenttextures.com/patterns/dark-mosaic.png");
	clip-path: polygon(0 0, 100% 0%, 100% 95%, 50% 100%, 0 95%);
}

.top-background {
	position: absolute;
	height: 500px;
	width: 100%;
	flex: 1;
	background-color: #000000;
	/* background-image: url('../assets/ibg_00.png');
     */
	clip-path: polygon(0 0, 100% 0%, 100% 95%, 50% 100%, 0 95%);
}

/* .triangle-mask-parent {
	top: 54vh;
	position: absolute;
	width: 100%;
	display: flex;
} */

/* .left-triangle-mask {
	height: 5vh;
	flex: 1;
	background-color: #fff;
	clip-path: polygon(0 0, 0% 100%, 100% 100%);
}

.right-triangle-mask {
	height: 5vh;
	flex: 1;
	background-color: #fff;
	clip-path: polygon(100% 1%, 0% 100%, 100% 100%);
} */

/* .top-background::after {
    content: "";
    position: absolute;
    top: 50vh;
	width: 100%;
	height: 40px;
	background: linear-gradient(45deg,  #000000,#075c74);
	background-size: 400% 400%;
	clip-path: polygon(51% 100%, 0 0, 100% 0);
	animation: gradient 15s ease infinite;
} */

.top-background-image {
	position: absolute;
	right: 12vw;
	height: 500px;
	width: 1000px;
	background-image: url('../assets/ibg_0xa.png');
	background-size: cover;
	background-repeat: no-repeat;
	clip-path: polygon(0 0, 100% 0%, 100% 95%, 50% 100%, 0 95%);
}

.display-port {
	width: 1024px;
	align-self: center
}

.ad-image1 {
	background-image: url('../assets/attraction-ad-image.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.ad-image2 {
	background-image: url('../assets/ibg_7.PNG');
	background-size: cover;
	background-repeat: no-repeat;
	background-position-y: top;
}

.ad-image3 {
	background-image: url('../assets/conference.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position-y: top;
}

.contact-info {
	flex-direction: row;
}

.contact-icon {
	margin-top: 2px;
	margin-left: 9px;
	margin-right: 9px;
}

.spanner {
	flex: 1
}

.contact-link {
	padding-top: 2px;
	font-size: 11px;
	margin-top: 8px;
	cursor: pointer;
	font-weight: bold;
	margin-right: 15px;
	display: flex;
}

.divider::after {
	content: '|';
	margin-left: 10px;
	margin-right: 10px;
	color: white;
}

.contact-number {
	margin-right: 10px;
}

.but {
	color: white;
	height: 32px;
	font-size: 11px;
	padding-left: 15;
	padding-right: 15px;
	padding: 8px;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: .5s;
	border-radius: 3px;
}

.but-orange {
	/* background: red; */
	background: linear-gradient(to right, #e60970, #ff5900);
}

.but-orange:hover {
	background: rgb(7, 160, 220) !important;
	color: white
}

.but-transparent {
	border: 2px solid #fff;
	padding: 7px
}

.but-transparent:hover {
	border: 2px solid rgb(7, 160, 220);
	background: rgb(7, 160, 220) !important;
	color: white
}

.arrow-bt {
	cursor: pointer;
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
	height: 22px;
	padding: 8px;
	width: 300px;
	text-align: center;
	background-color: #ff735c;
	color: white;
	transition: .3s;
}

.arrow-bt:hover {
	color: #121212;
	font-weight: 700;
}

.arrow-bt-parent {
	margin-top: 20px;
	display: flex;
	height: 22px;
}

.arrow-bt-parent::after {
	content: "";
	width: 20px;
	position: relative;
	right: 0;
	border-left: 18px solid #ff735c;
	border-top: 19px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 19px solid transparent;
}

.header-but {
	margin-left: 20px;
	margin-top: 2px;
}

.contact-header {
	background-color: #350a4e;
	height: 30px;
	color: white;
	font-size: 12px;
	font-weight: 600;
	justify-content: center;
}

.logo-text {
	font-weight: 700;
	font-size: 28px;
	color: rgb(7, 5, 5);
	font-family: Sarina !important;
}

.flex-row {
	flex-direction: row;
}

.flex-row-container {
	flex-direction: row;
}

.header {
	position: absolute;
	background-color: #1a0626;
	opacity: 0;
	height: 100px;
	width: 100%;
}

.header-content {
	position: absolute;
	width: 100%;
}

.main {
	display: inline-block;
}

.logo-area {
	flex-direction: row;
	padding: 0px;
}

.logo-text {
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-size: 1.5em;
	width: 9em;
	line-height: 1.2em;
	margin-top: 2px;
}

.footer-links {
	display: flex;
	justify-content: center;
	color: black;
	margin-top: 100px
}

/* raimbow logo */

/* .rainbow-text {
    font-family: 'Poppins';
    background: #CA4246;
    background-color: #CA4246;
    background: conic-gradient( #33a8ab 16.666%, #E16541 16.666%, #ff3900 33.333%, #F18F43 33.333%, #F18F43 50%, #c4e45f 50%, #d8ff5e 66.666%, #5af394 66.666%, #1b62ff 83.333%, #ff42e7 83.333%);
    background-size: 57%;
    background-repeat: repeat;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: rainbow-text-animation-rev 0.5s ease forwards;
    cursor: pointer;
}

.rainbow-text:hover {
    animation: rainbow-text-animation 0.5s ease forwards;
} */

.rainbow-text {
	color: white;
	background: white;
	/* background: linear-gradient(to right, #ff0000, #00ff95); */
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	padding-top: 6px;
	padding-left: 0px;
	padding-right: 5px
}

@keyframes rainbow-text-animation {
	0% {
		background-size: 57%;
		background-position: 0 0;
	}
	20% {
		background-size: 57%;
		background-position: 0 1em;
	}
	100% {
		background-size: 300%;
		background-position: -9em 1em;
	}
}

@keyframes rainbow-text-animation-rev {
	0% {
		background-size: 300%;
		background-position: -9em 1em;
	}
	20% {
		background-size: 57%;
		background-position: 0 1em;
	}
	100% {
		background-size: 57%;
		background-position: 0 0;
	}
}

a {
	color: white;
}

.contact-link {
	color: black
}

.selector-indicator {
	margin-top: 5px;
	margin-bottom: -3px;
	height: 3px;
	width: 100%;
	/* background: linear-gradient(to right, red, rgb(255, 81, 0)); */
	background: #52bad4;
}

.selector {
	justify-content: center;
	align-items: center;
	color: white
}

.selector svg {
	padding-left: 0;
	height: 21px;
	margin-top: 3px;
	font-size: 17px
}

.selector-text {
	padding-right: 0;
	padding-top: 5px;
	margin-left: 10px;
	font-size: 15px;
}

.selector .active {
	color: #52bad4;
	/* background: linear-gradient(to right, #ff5608, rgb(255, 81, 0)); */
	/* background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent; */
	padding-top: 0;
}

/* .selector:hover {
    color: red !important;
} */

.selector-container {
	justify-content: center;
	align-items: center;
	padding-right: 14px;
	cursor: pointer;
}

.trip-planner-banner {
	background-image: url('../assets/tourist_1.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	height: 250px;
	width: 100%;
	margin-top: 100px;
	background-position: center -130px;
	border: 1px solid #eee;
	border-radius: 10px;
}

/* .inline-parent div{
    display: inline-block;
} */

.header-background {
	border-bottom: 1px solid #f1dfdf;
	position: absolute;
	background: #fff;
	height: 60px;
	top: 0;
	width: 100%
}

.centered-content {
	justify-content: center;
	align-items: center;
	margin: auto;
}

.square-tab {
	font-weight: 300;
	color: #777;
	padding: 5px 15px;
	border: 1px solid #eee;
	margin: 5px;
	text-transform: capitalize;
}

.square-tab-active {
	font-weight: 700;
	color: #dd544e;
}

.grid-container-4 {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 12px;
}

.grid-item {
	overflow: hidden;
	width: 100%;
	height: 250px;
	font-size: 16px;
	margin-top: 12px;
	margin-bottom: 20px;
}

/*buttons*/

.listYourSpaceBt {
	background-color: #0a7fb5;
	border: 1px solid #0a7fb5;
	color: white;
	font-weight: bold;
	font-size: 11px;
	padding: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
	display: inline;
	max-height: 31px;
	border-radius: 20px;
	transition: .3s;
	cursor: pointer;
}

.listYourSpaceBt2 {
	background-color: #ff0000;
	border: 1px solid #ff0000;
	color: white;
	font-weight: bold;
	font-size: 11px;
	padding: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
	display: inline;
	max-height: 31px;
	border-radius: 20px;
	transition: .3s;
	cursor: pointer;
}

.listYourSpaceBt:hover {
	background-color: #fff;
	color: #111;
	border: 1px solid #111;
}

.listYourSpaceBt2:hover {
	background-color: #fff;
	color: #111;
	border: 1px solid #111;
}

.blue-grad-but {
	background: rgb(10, 127, 181);
	background: linear-gradient(to left, rgb(10, 127, 181), rgb(5, 159, 170));
	height: 40px;
	flex: 1;
	color: white;
	justify-content: center;
	align-items: center;
	display: flex;
	border-radius: 100px;
	cursor: pointer;
	transition: 1s;
	margin-left: 15px;
	margin-right: 15px;
}

.orange-but {
	background: #ff735c;
	height: 40px;
	flex: 1;
	color: white;
	justify-content: center;
	align-items: center;
	display: flex;
	border-radius: 100px;
	cursor: pointer;
	transition: 1s;
	margin-left: 15px;
	margin-right: 15px;
}

.home-tab .ant-tabs-tab {
	color: white;
}

.dropdown {
	position: relative;
	display: inline-block;
}

.dropdown-content {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	padding: 3px 13px;
	z-index: 1;
}

.dropdown:hover .dropdown-content {
	display: block;
}

.dropdown-content .circle-but {
	background-color: wheat;
	font-size: 11px;
	height: 30px;
	width: 30px;
	border-radius: 50%;
	justify-content: center;
	align-items: center;
	display: flex;
	margin: 10px;
	transition: .5s;
	cursor: pointer;
}

.dropdown-content .circle-but:hover {
	background-color: red;
	color: white;
}

.dropdown-content .num {
	padding-top: 12px;
}

.dropdown-content .title {
	padding-left: 10px;
	text-align: start;
	cursor: pointer;
}

.dropdown-content .title:hover {
	color: red;
}

.index-search-but {
	height: 100%;
	font-size: 16px;
	font-weight: bold;
	margin: 0;
	padding: 0;
	padding-left: 20px;
	padding-right: 20px;
	margin-left: 10px;
	border-radius: 3px
}

.autocomplete-places-parent {
	position: absolute;
	/* margin-left: 36px; */
	/* margin-top: 30px; */
	z-index: 1;
	top: 0;
	left: 0;
}

.autocomplete-places-parent ul {
	/* position: absolute; */
	/* top: 310px;
	left: 15%; */
	z-index: 5500;
	background-color: white;
	border: 1px solid #eee;
	padding: 0;
}

.autocomplete-places-parent ul {
	list-style-type: none;
	min-width: 300px;
}

.autocomplete-places-parent li {
	padding: 2px 0;
	border-bottom: 1px solid #eee;
	cursor: pointer;
	padding: 10px;
}

.autocomplete-places-parent li:hover {
	background-color: #eee;
}

.autocomplete-places-parent strong {
	color: tomato;
}

.autocomplete-places-parent li:last-child {
	border-bottom: none;
}

/*flip box*/

.flip-box {
	background-color: transparent;
	width: 100%;
	height: 70%;
	border: 1px solid #f1f1f1;
	perspective: 1000px;
}

.flip-box-inner {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
	transition: transform 0.8s;
	transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
	transform: rotateY(180deg);
}

.flip-box-front, .flip-box-back {
	position: absolute;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.flip-box-front {
	background-color: #bbb;
	color: black;
}

.flip-box-back {
	background-color: #000000;
	color: white;
	transform: rotateY(180deg);
}

footer a {
	color: grey !important;
}

footer a:hover {
	color: #dd544e !important;
}

.tab-parent {
	overflow-x: clip;
	position: relative;
	height: 340px;
	width: 100%;
	overflow-y: visible;
}

.tab {
	position: absolute;
	right: -100%;
	width: 100%
}

.tab-visible {
	display: block !important;
}

.transition {
	transition: all 0.5s ease-in-out;
}

.desktop-hidden {
	display: none;
}

.tab-content {
	margin-top: 35px;
	display: flex;
	flex-direction: row;
	place-items: flex-end;
}

.tab-caption {
	color: white;
	flex: 1 1 0%;
	text-align: start;
	font-weight: 500;
	font-size: 2em;
}

.tab-dropdown-parent {
	color: white;
	padding-top: 37px;
	cursor: pointer;
	margin-right: 10px;
}

.tab-input-fields {
	background: #12051e;
	flex: 1;
	border-radius: 0;
	padding: 10px;
	display: inline-flex;
	margin-top: 40px;
	border-radius: 3px;
	flex-shrink: 0;
}

.tab-input-fields-content {
	display: flex;
	flex: 1;
	flex-shrink: 0;
}

.tab-date-input-parent {
	border-radius: 0;
	padding: 6px;
	background: white;
	margin-left: 10px;
	flex: 1
}

.tab-date-input {
	font-size: 20px;
	padding-left: 10px;
	width: 150px;
	padding-top: 3px;
	text-transform: capitalize
}

.tab-location-search-input input {
	font-size: 24px;
}

.section-2 {
	margin-top: 516px;
	width: 100%;
	background: white;
}

.ad-image {
	margin-left: 5px;
	margin-right: 5px;
}

.footer-download-content {
	color: white;
	width: 500px;
	padding-top: 20px
}

.footer-parent {
	min-height: 800px;
	background: rgb(17, 17, 17);
}

.picker-parent {
	position: absolute;
	left: 10px;
	top: 42px;
}

.desktop-pad-top-37 {
	padding-top: 37px;
}
@media screen and (min-width: 1900px) {
	body {
		zoom: 1.2
	}
	.tab-parent {
		margin-top: 35px;
	}
	.top-section {
		height: 555px
	}
	.top-background {
		height: 555px
	}
	.top-background-texture {
		height: 555px
	}
	.top-background-image {
		height: 555px
	}
	.backdrop {
		height: 555px
	}
	.section-2{
		width:100%;
		margin-top: 572px;
	}
}