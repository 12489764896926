@media only screen and (max-width: 451px) {
    .signin-container {
        padding: 20px;
    }
    .selector-menu {
        display: none !important;
    }
    .main-column {
        padding: 10px;
    }
    .search-image {
        height: 200px;
        margin: 10px;
        width: calc(100% - 20px);
        background-position: center;
    }
    .selector-heading {
        font-size: 13px;
    }
    .selector-number-of-results {
        font-size: 10px;
    }
    .item-hotel-highlights {
        align-content:start;
        padding-right: 15px;
        border-left: 1px dotted #eee
     }
     .item-hotel-highlights-pusher{
         flex:0
     }
}