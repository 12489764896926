.property__gallery {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
  width: 100%;
  height: 500px;
  margin-bottom: 16px;
}

/*
  Container that wraps the whole subproperty section
*/

.property-container {
  max-width: 1024px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 16px;
}

.property-container__left {
  grid-column: 1/2;
  /* background-color: #C2C2C2; */
}

.property-container__main {
  height: fit-content;
  grid-column: 2/5;
  /* background-color: lawngreen; */
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 16px;
}

.property-container__main__app-bar {
  grid-column: 1/4;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
}

.property-container__main__app-bar__button {
  color: #0A7FB5;
  height: 36px;
  border-color: #0A7FB5;
  border-radius: 4px;
  font-weight: 600;
  border-width: 1px;
  padding-right: 16px;
  padding-left: 16px;
  white-space: nowrap;
}

.property-container__main__gallery {
  height: 480px;
  grid-column: 1/4;
}

.property-container__main__text {
  grid-column: 1/3;
  margin-top: 16px;
  margin-left: 16px;
  padding-right: 16px;
  border-right-width: 1px;
}

.property-container__main__map {
  grid-column: 3/4;
  margin-top: 16px;
}

.property-container__main__sub-property {
  grid-column: 1/4;
}

.sub-property__title {
  font-size: 28px;
  font-weight: 700;
  margin-left: 16px;
}

.sub-property__content {
  display: flex;
  flex-direction: flex-row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.sub-property__checkout {
  grid-column: 1/4;
}

/* Policy container styles */
.policy {
  padding: 16px;
  background-color: #0A7FB5;
  color: white;
  margin-bottom: 16px;
}

.policy__section__title {
  display: none;
}

.policy__title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.policy__title__icon {
  height: 24px;
  width: 24px;
  margin-right: 12px;
}

.policy__title__text {
  font-size: 20px;
  font-family: 600;
  position: relative;
  padding-bottom: 8px;
}

.policy__title__text::before {
  content: ' ';
  position: absolute;
  bottom: 0;
  height: 2px;
  width: calc(100% + 8px);
  margin-left: -8px;
  background-color: white;
}

.policy__list {
  padding: 16px;
  list-style-type: square;
  font-size: 14px;
}

/*
  Property card that is used to display sub properties
*/
.property-card {
  width: 244px;
  height: 280px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  border: 1px;
}

.property-card__cover {
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.property-card-content {
  height: 114px;
  width: 100%;
  background-color: white;
  padding: 16px;
  padding-bottom: 8px;
  position: absolute;
  bottom: 0;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  overflow: hidden;
  transition-timing-function: linear;
  transition-duration: 150ms;
}

.property-card-content:hover {
  height: 212px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.property-card-content__title {
  width: 100%;
  color: #0A7FB5;
  font-size: 20px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.property-card-content__subtitle {
  width: 100%;
  font-size: 12px;
  line-height: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.property-card-content__description {
  display: none;
  overflow: hidden;
  /* transition-property: display; */
  transition-timing-function: linear;
  transition-duration: 150ms;
  max-height: 100px;
  padding-top: 8px;
}

.property-card-content__description p {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.property-card-content:hover .property-card-content__description {
  display: block;
}

.property-card-content__button {
  height: 36px;
  color: #0A7FB5;
  margin-top: 8px;
  border-color: #0A7FB5;
  border-radius: 4px;
  padding-right: 16px;
  padding-left: 16px;
  font-weight: 700;
  font-size: 16px;
  margin-left: -8px;
  border-width: 1px;
  outline: none;
}

/**
  These are the styles for the overlay component that is displayed over the
  property.
*/
.overlay {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 8px;
  grid-auto-rows: min-content; /* Prevent from taking up excessive space */
  overflow-y: scroll;
  padding: 8px;
  width: 100%;
  height: 100vh;
  background-color: white;
  max-width: 1024px;
  position: absolute;
  right: 0;
  grid-auto-flow: dense;
}

.overlay__gallery {
  grid-column: 1/4;
  height: 480px;
}

.overlay__price-column {
  grid-column: 1/4;
}

.overlay__checkout {
  grid-column: 4/5;
}

.overlay__description {
  grid-column: 1/3;
  margin-top: 8px;
  height: min-content;
}

.overlay__policies {
  grid-column: 3/4;
  margin-top: 8px;
}

.overlay__extras {
  grid-column: 1/3;
  margin-top: 8px;
}

.overlay__extras__title {
  font-size: 24px;
  font-weight: 700;
}

.overlay__extras__content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

/**
This section contains styles for the unbookable overlay
*/

@media screen and (max-width: 1000px) {
  .property-container {
    display: flex;
    flex-direction: column-reverse;
  }

  .property-container__left {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-around;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .policy__section__title {
    display: block;
    width: 100%;
    font-size: 28px;
    margin-left: 16px;
    margin-bottom: 16px;
    font-weight: 700;
  }

  .policy {
    max-width: 344px;
  }

  .sub-property__content {
    padding-left: 8px;
    padding-right: 8px;
  }

  .property-container__main__gallery {
    margin: 16px;
    margin-bottom: 0px;
  }

  .overlay {
    width: 80%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .overlay__checkout {
    grid-column: 1/4;
    min-height: 100px;
    margin: 12px 0 12px 0;
  }
}

@media screen and (max-width: 451px) {
  .card {
    width: 344px;
  }

  .property-card {
    width: 100%;
    margin-left: 16px;
    margin-right: 16px;
  }

  .property-container__main__gallery {
    height: calc(100vw - 16px);
  }

  .property-container__main__text {
    grid-column: 1/4;
    margin-right: 16px;
    border-right-width: 0px;
  }

  .property-container__main__map {
    grid-column: 1/4;
    margin-right: 16px;
    margin-left: 16px;
  }

  .overlay {
    height: 100%;
    width: 100%;
    display: block;
  }

  .overlay__policies {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
