@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sarina&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');


html, body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	background: #ffffff;
	font-family: 'Open Sans', arial, sans-serif;
	font-weight: 400;
	color: #444;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	box-sizing: border-box;
}

#app {
	height: 100%;
	width:100vw
}
input{
	outline: none;
}
@media (min-width: 1900px) {
	body {
		zoom:1.15
	}
}

.paypal-button-container iframe {
  z-index: inherit;
}